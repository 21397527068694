{
  "name": "glps",
  "version": "1.39.10",
  "repository": {
    "type": "git",
    "url": "git+https://owens-illinois.visualstudio.com/LPS/_git/GLPS%20Front-end.git"
  },
  "scripts": {
    "ng": "ng",
    "start": "ng serve --configuration local",
    "start:dev": "ng serve --configuration development",
    "start:prod": "ng serve --configuration production",
    "start:qa": "ng serve --configuration qa",
    "build": "ng build",
    "watch": "ng build --watch --configuration development",
    "test": "ng test",
    "lint": "ng lint",
    "release": "release-it",
    "version": "npm run release -- --no-git",
    "prepare": "husky install"
  },
  "browser": {
    "https": false,
    "http": false,
    "util": false,
    "url": false
  },
  "private": true,
  "dependencies": {
    "@angular/animations": "^17.0.8",
    "@angular/cdk": "^17.0.2",
    "@angular/common": "^17.0.0",
    "@angular/compiler": "^17.0.0",
    "@angular/core": "^17.0.0",
    "@angular/forms": "^17.0.0",
    "@angular/material": "^17.0.2",
    "@angular/platform-browser": "^17.0.0",
    "@angular/platform-browser-dynamic": "^17.0.0",
    "@angular/router": "^17.0.0",
    "@microsoft/signalr": "^8.0.0",
    "@ngx-translate/core": "^15.0.0",
    "@ngx-translate/http-loader": "^8.0.0",
    "html2canvas": "^1.4.1",
    "jspdf": "^2.5.1",
    "luxon": "^3.4.4",
    "ng-qrcode": "^17.0.0",
    "rxjs": "~7.8.0",
    "tslib": "^2.3.0",
    "zone.js": "~0.14.2"
  },
  "devDependencies": {
    "@angular-devkit/build-angular": "^17.0.5",
    "@angular-eslint/builder": "17.1.1",
    "@angular-eslint/eslint-plugin": "17.1.1",
    "@angular-eslint/eslint-plugin-template": "17.1.1",
    "@angular-eslint/schematics": "17.1.1",
    "@angular-eslint/template-parser": "17.1.1",
    "@angular/cli": "^17.0.5",
    "@angular/compiler-cli": "^17.0.0",
    "@commitlint/cli": "^18.4.3",
    "@commitlint/config-conventional": "^18.4.3",
    "@release-it/conventional-changelog": "^8.0.1",
    "@release-it/keep-a-changelog": "^5.0.0",
    "@types/jasmine": "~5.1.0",
    "@types/luxon": "^3.4.2",
    "@typescript-eslint/eslint-plugin": "6.13.1",
    "@typescript-eslint/parser": "6.13.1",
    "auto-changelog": "^2.4.0",
    "autoprefixer": "^10.4.16",
    "eslint": "^8.54.0",
    "husky": "^8.0.3",
    "jasmine-core": "~5.1.0",
    "karma": "~6.4.0",
    "karma-chrome-launcher": "~3.2.0",
    "karma-coverage": "~2.2.0",
    "karma-jasmine": "~5.1.0",
    "karma-jasmine-html-reporter": "~2.1.0",
    "lint-staged": "^15.2.0",
    "postcss": "^8.4.32",
    "prettier": "3.1.1",
    "release-it": "^17.2.1",
    "tailwindcss": "^3.4.0",
    "typescript": "~5.2.2"
  }
}
